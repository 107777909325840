import { StorageSDK } from '@/packages/artebeaute-sdk/src'

const getCategories = async () => {
  const images = await StorageSDK.Upload.getBanque()

  const tabNameCategories = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[1] === 'categories') {
      if (!tabNameCategories.includes(words[2]) && words[2] !== '') {
        tabNameCategories.push(words[2])
      }
    }
  })

  const imagesByCategories = []
  tabNameCategories.forEach(name => {
    imagesByCategories.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategories.forEach(imgCat => {
      if (
        words[1] === 'categories' &&
        imgCat.title === words[2] &&
        !!words[3]
      ) {
        imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
      }
    })
  })

  return imagesByCategories
}

const getGiftMail = async () => {
  const images = await StorageSDK.Upload.getBanque()

  const tabNameMailGift = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[1] === 'bons cadeaux' && words[2] === 'mail') {
      if (!tabNameMailGift.includes(words[3]) && words[3] !== '') {
        tabNameMailGift.push(words[3])
      }
    }
  })

  const imagesByCategoriesMail = []
  tabNameMailGift.forEach(name => {
    imagesByCategoriesMail.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategoriesMail.forEach(imgCat => {
      if (
        words[1] === 'bons cadeaux' &&
        words[2] === 'mail' &&
        imgCat.title === words[3] &&
        !!words[4]
      ) {
        imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
      }
    })
  })

  return imagesByCategoriesMail
}

const getGiftPdf = async () => {
  const images = await StorageSDK.Upload.getBanque()

  const tabNamePdfGift = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[1] === 'bons cadeaux' && words[2] === 'pdf') {
      if (!tabNamePdfGift.includes(words[3]) && words[3] !== '') {
        tabNamePdfGift.push(words[3])
      }
    }
  })

  const imagesByCategoriesPdf = []
  tabNamePdfGift.forEach(name => {
    imagesByCategoriesPdf.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategoriesPdf.forEach(imgCat => {
      if (
        words[1] === 'bons cadeaux' &&
        words[2] === 'pdf' &&
        imgCat.title === words[3] &&
        !!words[4]
      ) {
        imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
      }
    })
  })

  return imagesByCategoriesPdf
}

const getGeogroupments = async () => {
  const images = await StorageSDK.Upload.getBanque()

  const tabNameGeogroupments = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[1] === 'geogroupments') {
      if (!tabNameGeogroupments.includes(words[2]) && words[2] !== '') {
        tabNameGeogroupments.push(words[2])
      }
    }
  })

  const imagesByCategories = []
  tabNameGeogroupments.forEach(name => {
    imagesByCategories.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategories.forEach(imgCat => {
      if (
        words[1] === 'geogroupments' &&
        imgCat.title === words[2] &&
        !!words[3]
      ) {
        imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
      }
    })
  })

  return imagesByCategories
}

const getBoutiques = async () => {
  const images = await StorageSDK.Upload.getBanque()

  const tabNameBoutiques = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[1] === 'boutiques') {
      if (!tabNameBoutiques.includes(words[2]) && words[2] !== '') {
        tabNameBoutiques.push(words[2])
      }
    }
  })

  const imagesByCategories = []
  tabNameBoutiques.forEach(name => {
    imagesByCategories.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategories.forEach(imgCat => {
      if (words[1] === 'boutiques' && imgCat.title === words[2] && !!words[3]) {
        imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
      }
    })
  })

  return imagesByCategories
}

const getArticles = async () => {
  const images = await StorageSDK.Upload.getBanque()

  const tabNameArticles = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[1] === 'articles') {
      if (!tabNameArticles.includes(words[2]) && words[2] !== '') {
        tabNameArticles.push(words[2])
      }
    }
  })

  const imagesByCategories = []
  tabNameArticles.forEach(name => {
    imagesByCategories.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategories.forEach(imgCat => {
      if (words[1] === 'articles' && imgCat.title === words[2] && !!words[3]) {
        imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
      }
    })
  })

  return imagesByCategories
}

const getThem = async (nickname, folder, gift) => {
  const images = await StorageSDK.Upload.getBanqueByNickname(nickname)

  const tabCategories = []
  images.data.forEach(img => {
    const words = img.name.split('/')
    if (words[3] === 'bank' && words[4] === folder) {
      if (
        gift &&
        words[5] === gift &&
        !tabCategories.includes(words[6]) &&
        words[6] !== ''
      ) {
        tabCategories.push(words[6])
      } else if (
        !gift &&
        !tabCategories.includes(words[5]) &&
        words[5] !== ''
      ) {
        tabCategories.push(words[5])
      }
    }
  })

  const imagesByCategories = []
  tabCategories.forEach(name => {
    imagesByCategories.push({
      title: name,
      src: []
    })
  })

  images.data.forEach(img => {
    const words = img.name.split('/')

    imagesByCategories.forEach(imgCat => {
      if (words[3] === 'bank' && words[4] === folder) {
        if (
          gift &&
          words[5] === gift &&
          imgCat.title === words[6] &&
          !!words[7]
        ) {
          imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
        } else if (!gift && imgCat.title === words[5] && !!words[6]) {
          imgCat.src.push('https://ik.imagekit.io/tiwpkbgdgfs/' + img.name)
        }
      }
    })
  })

  return imagesByCategories
}

export default {
  getCategories,
  getGiftMail,
  getGiftPdf,
  getGeogroupments,
  getBoutiques,
  getArticles,
  getThem
}
