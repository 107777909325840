<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    min="0"
    max="100"
    outlined
    type="number"
    step="0.000001"
    :label="label"
    suffix="%"
    :hide-details="hideDetails"
    @blur="minMax"
  />
</template>

<script>
export default {
  name: 'pourcentage',
  props: {
    value: {
      type: Number,
      default: () => 100
    },
    label: {
      type: String,
      default: 'Pourcentage'
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    minMax() {
      if (this.value < 0) {
        this.$emit('input', 0)
      }
      if (this.value > 100) {
        this.$emit('input', 100)
      }
      this.$emit('blur')
    }
  }
}
</script>

<style scoped></style>
