<template>
  <div>
    <div v-if="!selection" class="text-center">
      <div v-if="disabled && !loading">{{ $t('home.comingSoon') }}</div>

      <v-btn
        :disabled="disabled || loading"
        color="dark"
        :dark="!disabled"
        :class="{ 'px-15': loading }"
        @click="selection = true"
      >
        <v-overlay
          v-if="loading"
          style="position: absolute; top: 0; bottom: 0; left: 0; right: 0"
        >
          <v-progress-circular indeterminate />
        </v-overlay>
        <span v-else>
          {{ $t('image.ourImagesBank') }}
        </span>
      </v-btn>
    </div>
    <v-dialog v-model="selection" width="950">
      <v-card v-if="selection">
        <div>
          <v-card-title>{{ $t('image.ourImagesBank') }}</v-card-title>
          <v-divider />
          <div class="px-15 py-3">
            <v-autocomplete
              v-model="selectedTitles"
              :items="getTitle"
              :label="$t('other.research')"
              outlined
              rounded
              hide-details
              multiple
              chips
              deletable-chips
              clearable
              small-chips
              @change="search"
            />
          </div>
          <v-divider />
        </div>

        <div style="height: 65vh; overflow: auto">
          <div v-for="image in tabImages" :key="image.objectId">
            <v-card-subtitle
              class="mt-5 font-weight-bold text-uppercase"
              style="font-size: 1.1rem"
            >
              {{ image.title }}
            </v-card-subtitle>
            <v-card-text class="d-flex align-center justify-start flex-wrap">
              <div v-for="src in image.src" :key="src">
                <v-img
                  :src="src"
                  height="250"
                  width="250"
                  class="ma-5 elevation-5"
                  @click="addImage(src)"
                >
                  <v-icon
                    v-if="
                      imagesAlreadySelected.find(i => i.url === src) && !single
                    "
                    style="
                      position: absolute;
                      right: 2px;
                      top: 2px;
                      background-color: white;
                      border-radius: 100px;
                    "
                    color="blue"
                    size="20"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else-if="selectedImages.find(i => i.url === src)"
                    style="
                      position: absolute;
                      right: 2px;
                      top: 2px;
                      background-color: white;
                      border-radius: 100px;
                    "
                    color="green"
                    size="20"
                  >
                    mdi-check-circle
                  </v-icon>
                </v-img>
              </div>
            </v-card-text>
          </div>
        </div>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="selection = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="save" color="success" tile>
              {{ $t('settings.emails.save') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'fileExplorer',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    imagesAlreadySelected: {
      type: Array,
      default: () => []
    },
    single: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => {
    return {
      tabImages: [],
      selection: false,
      selectedImages: [],
      selectedTitles: [],
      disabled: false
    }
  },
  computed: {
    getTitle() {
      const tabTitles = []
      this.images.map(img => {
        let title = img.title[0].toUpperCase() + img.title.slice(1)
        title = title.replace('_', ' ')
        tabTitles.push(title)
      })
      return tabTitles
    }
  },
  mounted() {
    if (this.images && this.images.length) {
      this.images.map(img => {
        img.title = img.title.replace('_', ' ')
        this.tabImages.push(img)
      })

      if (this.single) {
        this.selectedImages = this.imagesAlreadySelected
      }
    } else {
      this.disabled = true
    }
  },
  methods: {
    addImage(url) {
      if (this.single) {
        this.selectedImages = []
        this.selectedImages.push({ url })
      } else {
        if (
          !this.selectedImages.find(i => i.url === url) &&
          !this.imagesAlreadySelected.find(i => i.url === url)
        ) {
          this.selectedImages = [...this.selectedImages, { url }]
        } else if (!this.imagesAlreadySelected.find(i => i.url === url)) {
          const index = this.selectedImages.findIndex(i => i.url === url)
          this.selectedImages.splice(index, 1)
        }
      }
    },
    save() {
      this.$emit('uploadImages', this.selectedImages)
      this.selection = false
    },
    search() {
      let images = this.images

      const imagesToReturn = []
      if (this.selectedTitles && this.selectedTitles.length) {
        images.forEach(img => {
          this.selectedTitles.forEach(selImg => {
            if (img.title === selImg.toLowerCase()) {
              imagesToReturn.push(img)
            }
          })
        })

        images = images.filter(img => imagesToReturn.includes(img))
      }
      this.tabImages = images
    }
  },
  watch: {
    selection() {
      if (!this.selection && !this.single) {
        this.selectedImages = []
      }
    },
    images() {
      this.tabImages = []
      this.disabled = false

      if (this.images && this.images.length) {
        this.images.map(img => {
          img.title = img.title.replace('_', ' ')
          this.tabImages.push(img)
        })

        if (this.single) {
          this.selectedImages = this.imagesAlreadySelected
        }
      } else {
        this.disabled = true
      }
    }
  }
}
</script>
