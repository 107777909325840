export default {
  shop: {
    default: [1900, 400],
    grid: [1200, 400]
  },
  categories: {
    default: [1900, 300],
    cards: [500, 500]
  },
  services: {
    default: [1900, 400],
    whole: [900, 300]
  },
  geogroups: {
    default: [1900, 325],
    grid: [950, 400],
    grid2: [600, 400]
  }
}
